export function randomReplaceWithSymbol(inputString, probability) {
    // Ensure the probability is between 0 and 1
    if (probability < 0 || probability > 1) {
        throw new Error("Probability must be between 0 and 1.");
    }

    // Fixed replacement list
    const replacementList = ['░', '▓', '▒'];

    // Function to get a random item from the replacement list
    function getRandomReplacement() {
        return replacementList[Math.floor(Math.random() * replacementList.length)];
    }

    // Split the string into an array of characters
    let result = inputString.split('').map(char => {
        // Generate a random number between 0 and 1
        let randomNum = Math.random();
        // Replace the character with a random one from the list based on the probability
        return randomNum < probability ? getRandomReplacement() : char;
    });

    // Join the array back into a string
    return result.join('');
}