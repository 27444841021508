
import React from 'react';
import './App.css';
import SpaceBackground from './SpaceBackground';
import GlitchText from './GlitchText';
import nightParty from './img/night_party.webp'; 

function Page6() {
    let fontSize="2vh";
    return (
        <div>
            <SpaceBackground />
            <div className="glitch">
                    <GlitchText text={"Uma semana após terem começado"} fontSize={fontSize} color="#ff6ec7"/>
                    <GlitchText text={"a namorar, vocês foram em uma festa"} fontSize={fontSize} color="#ff6ec7"/>
                    <GlitchText text={"de um grupo da universidade."} fontSize={fontSize} color="#ff6ec7"/>
                    <br/>
                    <img src={nightParty} style={{ width: 'auto', height: '30vh' }} alt="" />
                    <br/>
                    <br/>
                    <br/>
                    <GlitchText text={"<watchers> <question>"} color="#f88379" fontSize={fontSize}/>
                    <GlitchText text={"what group was that? what happened that day?"} color="#ff00ff" fontSize={fontSize}/>

            </div>
        </div>

    );
}

export default Page6;

