// GlitchCounter.js
import React, { useState, useEffect } from 'react';
import './App.css';
import { randomReplaceWithSymbol } from './utils';

function GlitchCounter() {
    const [text, setText] = useState('');
    const [footerText, setFooterText] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            // Generate random two-digit numbers for left and right values
            const digits = '0123456789!@#$%^&*()[]\/;';
            let days = '';
            let leftText = '';
            let rightText = '';
            const length = 2; // Two digits on each side
            for (let i = 0; i < length; i++) {
                days += digits.charAt(Math.floor(Math.random() * digits.length));
                leftText += digits.charAt(Math.floor(Math.random() * digits.length));
                rightText += digits.charAt(Math.floor(Math.random() * digits.length));
            }
            let new_text = days + ' days and ' + leftText + ':' + rightText + ' hours'
            new_text = randomReplaceWithSymbol(new_text, 0.1);
            setText(new_text)
            setFooterText(randomReplaceWithSymbol("until <UNKNOWN>", 0.1))

        }, 500); // Update every 500 milliseconds

        return () => clearInterval(interval);
    }, []);

    return (
        // <div className="counter-container">
        <div className="glitch">
            <span className="glitch-text" style={{ fontSize: '3.5vh', color: '#f88379' }} data-text={text}>{text}</span>
            <span className="glitch-text" style={{ fontSize: '3.5vh', color: '#ff6ec7' }} data-text={footerText}>{footerText}</span>
        </div>
        // </div>
    );
}

export default GlitchCounter;
