import React from 'react';
import GlitchCounter from './GlitchCounter';
import './App.css';
import SpaceBackground from './SpaceBackground';
import GlitchText from './GlitchText';
import classroomImage from './img/classroom1.webp';

function Page1() {
    let fontSize="2vh";
    return (
        <div>
            <SpaceBackground />
            <div className="glitch">
                    <GlitchText text={"Você está em uma sala de aula"} fontSize={fontSize} color="#ff6ec7"/>
                    <GlitchText text={"assistindo a uma aula de computação"} fontSize={fontSize} color="#ff6ec7"/>
                    <br/>
                    <img src={classroomImage} style={{ width: 'auto', height: '30vh' }} alt="" />
                    <br/>
                    <GlitchText text={"O professor pergunta se alguem conhece"} fontSize={fontSize}/>
                    <GlitchText text={"o teorema da incompletude de Gödel"} fontSize={fontSize}/>
                    <br/>
                    <GlitchText text={"Um estudante de camiseta preta levanta"} fontSize={fontSize}/>
                    <GlitchText text={"a mão"} fontSize={fontSize}/>
                    <GlitchText text={"O professor pede que o estudante diga"} fontSize={fontSize}/>
                    <GlitchText text={"ao resto da turma"} fontSize={fontSize}/>
                    <br/>
                    <br/>
                    <GlitchText text={"<watchers> <question>"} color="#f88379" fontSize={fontSize}/>
                    <GlitchText text={"what is the name of this student?"} color="#ff00ff" fontSize={fontSize}/>

            </div>
        </div>

    );
}

export default Page1;

