import React from 'react';
import './App.css';
import SpaceBackground from './SpaceBackground';
import GlitchText from './GlitchText';
import icecreamImage from './img/icecream.webp';

function Page2() {
    let fontSize="2vh";
    return (
        <div>
            <SpaceBackground />
            <div className="glitch">
                    <GlitchText text={"Você foi tomar sorvete"} fontSize={fontSize} color="#ff6ec7"/>
                    <GlitchText text={"com o menino que conheceu"} fontSize={fontSize} color="#ff6ec7"/>
                    <br/>
                    <img src={icecreamImage} style={{ width: 'auto', height: '30vh' }} alt="" />
                    <br/>
                    <GlitchText text={"Vocês já estavam interessados"} fontSize={fontSize}/>
                    <GlitchText text={"um no outro, mesmo que tivessem"} fontSize={fontSize}/>
                    <GlitchText text={"acabado de se conhecer."} fontSize={fontSize}/>
                    <br/>
                    <br/>
                    <GlitchText text={"<watchers> <question>"} color="#f88379" fontSize={fontSize}/>
                    <GlitchText text={"where did you have icecream?"} color="#ff00ff" fontSize={fontSize}/>

            </div>
        </div>

    );
}

export default Page2;

