// GlitchCounter.js
import React, { useState, useEffect } from 'react';
import './App.css';
import { randomReplaceWithSymbol } from './utils';

function GlitchText({ text, noise = 0.1, fontSize = null, color = null }) {
    const [textState, setTextState] = useState(randomReplaceWithSymbol(text, noise));

    useEffect(() => {
        const interval = setInterval(() => {
            setTextState(randomReplaceWithSymbol(text, noise))
        }, 500); // Update every 500 milliseconds

        return () => clearInterval(interval);
    }, [text, noise]);

    let style = {};
    if (fontSize) {
        style.fontSize = fontSize;
    }
    if (color) {
        style.color = color;
    }

    return (
        <div className="glitch">
            <span className="glitch-text" style={style} data-text={textState}>{textState}</span>
        </div>
    );
}

export default GlitchText;
