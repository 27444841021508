import React from 'react';
import GlitchCounter from './GlitchCounter';
import './App.css';
import SpaceBackground from './SpaceBackground';
import GlitchText from './GlitchText';
import majuImage from './img/maju-sticker.png';


function Home() {
  return (
    <div>
      <SpaceBackground />
      <div className="container">
        <GlitchText text={"unknown watchers"} color={"#c154c1"} fontSize={"5vh"} />
        <div className="glitch">
          <GlitchText text={"something is coming."} />
          <GlitchText text={"<you> must be <avaliated>."} />
          <GlitchText text={"<soon>."} />
        </div>
        <GlitchCounter />
        <div className="glitch">
          <GlitchText text={"this is important to <lifeform>"} />
          <img src={majuImage} style={{ width: '20%', height: 'auto' }} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Home;

