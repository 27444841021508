import React from 'react';
import './App.css';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Page5 from './Page5';
import Page6 from './Page6';
import Home from './Home';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/memory1" element={<Page1 />} />
          <Route path="/memory2" element={<Page2 />} />
          <Route path="/memory3" element={<Page3 />} />
          <Route path="/memory4" element={<Page4 />} />
          <Route path="/memory5" element={<Page5 />} />
          <Route path="/memory6" element={<Page6 />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

