import React from 'react';
import './App.css';
import SpaceBackground from './SpaceBackground';
import GlitchText from './GlitchText';
import cinemaImage from './img/cinema.webp';

function Page5() {
    let fontSize="2vh";
    return (
        <div>
            <SpaceBackground />
            <div className="glitch">
                    <GlitchText text={"Alguns dias depois de comer bolo,"} fontSize={fontSize} color="#ff6ec7"/>
                    <GlitchText text={"vocês foram ao cinema, onde se"} fontSize={fontSize} color="#ff6ec7"/>
                    <GlitchText text={"beijaram pela primeira vez."} fontSize={fontSize} color="#ff6ec7"/>

                    <br/>
                    <img src={cinemaImage} style={{ width: 'auto', height: '30vh' }} alt="" />
                    <br/>
                    <br/>
                    <br/>
                    <GlitchText text={"<watchers> <question>"} color="#f88379" fontSize={fontSize}/>
                    <GlitchText text={"who kissed first? (tricky question)"} color="#ff00ff" fontSize={fontSize}/>

            </div>
        </div>

    );
}

export default Page5;

