import React from 'react';
import './App.css';
import SpaceBackground from './SpaceBackground';
import GlitchText from './GlitchText';
import redVelvetImg from './img/red_velvet.webp';

function Page4() {
    let fontSize="2vh";
    return (
        <div>
            <SpaceBackground />
            <div className="glitch">
                    <GlitchText text={"Você comentou com o menino sobre"} fontSize={fontSize} color="#ff6ec7"/>
                    <GlitchText text={"um sabor de bolo que você gostava,"} fontSize={fontSize} color="#ff6ec7"/>
                    <GlitchText text={"mas que ele nunca tinha experimentado."} fontSize={fontSize} color="#ff6ec7"/>

                    <br/>
                    <img src={redVelvetImg} style={{ width: 'auto', height: '30vh' }} alt="" />
                    <br/>
                    <GlitchText text={"Ele chamou você para ir com ele"} fontSize={fontSize}/>
                    <GlitchText text={"em um café para experimentar."} fontSize={fontSize}/>
                    <br/>
                    <br/>
                    <GlitchText text={"<watchers> <question>"} color="#f88379" fontSize={fontSize}/>
                    <GlitchText text={"what type of cake was that? where did you go?"} color="#ff00ff" fontSize={fontSize}/>

            </div>
        </div>

    );
}

export default Page4;

