import React from 'react';
import './App.css';
import SpaceBackground from './SpaceBackground';
import GlitchText from './GlitchText';
import walkingHomeImage from './img/walking-home.webp';

function Page3() {
    let fontSize="2vh";
    return (
        <div>
            <SpaceBackground />
            <div className="glitch">
                    <GlitchText text={"O menino começou a te acompanhar"} fontSize={fontSize} color="#ff6ec7"/>
                    <GlitchText text={"por todo lugar."} fontSize={fontSize} color="#ff6ec7"/>
                    <br/>
                    <img src={walkingHomeImage} style={{ width: 'auto', height: '30vh' }} alt="" />
                    <br/>
                    <GlitchText text={"Todo dia após a aula, ele pegava o"} fontSize={fontSize}/>
                    <GlitchText text={"ônibus com você, e te levava até"} fontSize={fontSize}/>
                    <GlitchText text={"a sua casa."} fontSize={fontSize}/>
                    <br/>
                    <br/>
                    <GlitchText text={"<watchers> <question>"} color="#f88379" fontSize={fontSize}/>
                    <GlitchText text={"how were you feeling at this time?"} color="#ff00ff" fontSize={fontSize}/>

            </div>
        </div>

    );
}

export default Page3;

