import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
// import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.
const { loadEmittersPlugin } = require("@tsparticles/plugin-emitters");

const SpaceBackground = () => {
  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      await loadAll(engine);
      //await loadFull(engine);
      // await loadSlim(engine);
      //await loadBasic(engine);
      await loadEmittersPlugin(engine);

    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      fpsLimit: 60,
      background: {
        color: "#000000",
      },
      particles: {
        number: {
          value: 0,
        },
        size: {
          value: { min: 0.8, max: 20 },
          animation: {
            enable: true,
            speed: 1,
            sync: false,
            startValue: "min",
            destroy: "max"
          }
        },
        opacity: {
          value: { min: 0.0, max: 1.0 },
          animation: {
            enable: true,
            speed: 2,
            startValue: "min",
            sync: false,
          },
        },
        move: {
          enable: true,
          speed: { min: 50, max: 300 },
          direction: "outside",
          outModes: {
            default: "destroy",
          },
          straight: false,
          random: false,
          warp: false,
          size: true,
          // animation: {
          //   enable: true,
          //   speed: 20,
          //   startValue: "min",
          //   sync: false,
          // },
        },

        color: {
          value: "#ff6ec7",
        },
      },
      emitters: {
        direction: "none", // Emission direction (optional)
        position: {
          x: 50, // Emitter position (percentage of the canvas width)
          y: 50, // Emitter position (percentage of the canvas height)
        },
        rate: {
          quantity: 20,  // Number of particles emitted per second
          delay: 0.1,    // Delay between emissions
        },
        size: {
          width: 0,    // Width of the emission area
          height: 0,     // Height of the emission area
        },
      },
      // interactivity: {
      //   events: {
      //     onHover: {
      //       enable: true,
      //       mode: "repulse",
      //     },
      //     resize: true,
      //   },
      //   modes: {
      //     repulse: {
      //       distance: 100,
      //       duration: 0.4,
      //     },
      //   },
      // },
      detectRetina: true,
    }),
    [],
  );

  if (init) {
    return (
      <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={options}
      />
    );
  }

  return <></>;
};

export default SpaceBackground;